import React from 'react'
import Link from 'gatsby-link'
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

export default function Template({data}) {
    const post = data.markdownRemark

    return (
        <Layout>
            <BlogWrapper>
            <SEO title={post.frontmatter.topic} />
        
            <br />
            <h1>{post.frontmatter.title}</h1>
            
            <div dangerouslySetInnerHTML={{__html: post.html}} />
            <Posting>
            <h4>Posted by {post.frontmatter.author} on {post.frontmatter.date}</h4>
            </Posting>
        
        </BlogWrapper>
        </Layout>
    )
}

export const postQuery = graphql `
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path }}){
            html
            frontmatter {
                path
                title
                author
                date
                topic
                desc
            }
        }
    }
`
const Posting = styled.div`
    display: grid;
    padding: 2rem;
    text-align: right;

`
const BlogWrapper = styled.div`
  display: grid;
  padding: 2rem; 
  a {
    color: black;
  }


  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    margin-left: 20px;
    margin-right: 20px;
    
    .display-1 {
      font-size: 2.4rem;
      text-align: left;
    }
  }
`

const TextWrapper = styled.div`
  display: grid:
  padding: 4rem;
  font-size: 10rem;
  margin-left: 10px;
  margin-right: 10px;
`

